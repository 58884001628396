<script setup lang='ts'>
import { addHost } from '@qctsw/common'
import type { redBlackItem } from '@/shared/types'

const props = withDefaults(defineProps<{
  item: redBlackItem
  type?: 'red' | 'black'
}>(), {
  type: 'red',
})
</script>

<template>
  <div v-if="props.item" class="relative overflow-hidden p-sm" :class="[type === 'red' ? 'red' : 'black']">
    <div class="flex">
      <div class="w-30 h-30 p-1.5 rounded mr-2.5 flex-shrink-0 bg-slate-1 border-1 border-slate-2">
        <img loading="lazy" class="w-full h-full object-cover" :src="addHost(item.carBrandLogo ?? item.brandCoverImg)">
      </div>
      <div class="flex-grow min-w-0 flex flex-col justify-between">
        <div class="font-bold">
          {{ item.carVendorName ? item.carVendorName : item.vendorName }}
        </div>
        <div class="flex items-center justify-between">
          <div>
            <div class="text-gray-5 text-3">
              投诉量/宗
            </div>
            <div class="text-5 font-mono text-center">
              {{ item.total }}<span class="text-gray-5 text-3 color-red-5">（近两年）</span>
            </div>
          </div>
          <div>
            <div class="text-gray-5 text-3">
              解决率
            </div>
            <div class="text-5 font-mono text-center">
              {{ Math.floor((item.finishRate ?? 0) * 100) }}%
            </div>
          </div>
          <div>
            <div class="text-gray-5 text-3">
              QT分
            </div>
            <div class="text-5 font-mono text-center">
              {{ item.qt }}
            </div>
          </div>
        </div>
        <NProgress
          :style="{ '--n-fill-color': 'var(--color)' }"
          type="line"
          :show-indicator="false"
          :percentage="Math.floor((item.finishRate ?? 0) * 100)"
          :height="20"
          :border-radius="0"
        />
      </div>
    </div>
    <div class="tips">
      {{ type === 'red' ? '红榜' : '黑榜' }}
    </div>
    <div class="flex text-3.5 mt-6">
      <div class="flex-shrink-0 tracking-1">
        理由：
      </div>
      <div>{{ item.reason }}</div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.red {
  --color: theme(colors.red.600);
}

.black {
  --color: theme(colors.slate.900);
}

.tips {
  --uno: w-20 bg-[var(--color)] rounded-bl rounded-tr text-white text-3.5 absolute top-0 right-0 text-center py-1.5;
}
</style>
